import { useQuery } from '@apollo/client'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { map } from 'ramda'
import React from 'react'
import styled from 'styled-components'

import NewsBgImg from '../../assets/static/homepage/newsBg.png'
import Button from '../../components/Button'
import { SpecialNews } from '../../components/Cards'
import { GridItem, GridLayout } from '../../components/Grid'
import { H2TitleWithSubTitle } from '../../components/Titles/H2Title'
import { space } from '../../constants/length'
import { White } from '../../constants/newColor'
import { viewport } from '../../constants/viewport'
import { NewsListQuery, NewsListQueryVariables } from '../../types/types'
import { newsListQuery } from '../news/List'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const BgImgWrapper = styled.div`
  width: 100%;
  background-image: url(${NewsBgImg});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 70%;
`

const NewsWrapper = styled.div`
  background-color: ${White};
  width: 90%;
  padding: 0 20%;
  margin: 0 auto;
  @media (max-width: ${viewport.desktop - 1}px) {
    width: 100%;
    padding: 0 10%;
  }
`
const ButtonLink = styled.a`
  display: inline-block;
  margin-top: ${space.xl}px;
  @media (max-width: ${viewport.tablet - 1}px) {
    margin-top: 0px;
  }
`

const NewsSection = () => {
  const { formatMessage } = useIntl()
  const { data } = useQuery<NewsListQuery, NewsListQueryVariables>(newsListQuery, {
    variables: {
      limit: 3
    }
  })

  const displayNewsList = data?.newsList?.docs ?? []
  return (
    <Wrapper>
      <H2TitleWithSubTitle title={formatMessage({ id: 'title.news' })} subtitle={formatMessage({ id: 'subtitle.news' })} />
      <BgImgWrapper>
        <GridLayout templateColumns={['100%', '1fr 60% 1fr']}>
          <GridItem column={['unset', '2 / 3']}>
            <NewsWrapper>
              <GridLayout templateColumns={['100%']} rowGap={[`${space.xs}px`]}>
                {map(
                  ({ id, title, alias, publishedDate }) => (
                    <SpecialNews newsId={id} alias={alias} title={title} date={moment(publishedDate).format('YYYY.MM.DD')} />
                  ),
                  displayNewsList
                )}
              </GridLayout>
            </NewsWrapper>
          </GridItem>
        </GridLayout>
      </BgImgWrapper>
      <ButtonLink href='/news'>
        <Button label='MORE &#9658;' type='secondary' />
      </ButtonLink>
    </Wrapper>
  )
}

export default NewsSection
