import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import OrthodontistImg1 from '../../assets/static/homepage/orthodontist1.jpg'
import OrthodontistImg2 from '../../assets/static/homepage/orthodontist2.jpg'
import { GridItem, GridLayout } from '../../components/Grid'
import { H2TitleWithSubTitle } from '../../components/Titles/H2Title'
import { Primary } from '../../constants/newColor'
import { Body } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { homePageMaxWidth } from './ClinicSection'

const Wrapper = styled.section`
  width: 100%;
  max-width: ${homePageMaxWidth}px;
  margin: 0 auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: 392px;
  }
`

const ContentContainer = styled.div`
  width: 100%;
  height: 616px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media (max-width: ${viewport.tablet - 1}px) {
    height: auto;
  }
`

const Content = styled.div`
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 24px;
  top: 0;
  :nth-child(even) {
    flex-direction: row;
    align-items: flex-end;
    bottom: 0;
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    position: static;
    justify-content: center;
    padding: 0 24px;
    flex-direction: column-reverse;
    align-items: center;
    margin-bottom: 60px;
    :nth-child(even) {
      flex-direction: column-reverse;
      align-items: center;
      bottom: 0;
      margin-bottom: 24px;
    }
  }
`

const Img = styled.img`
  width: 290px;
  height: 348px;
  border: 1px solid ${Primary};
  border-radius: 8px;
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 65%;
    min-width: 130.8px;
    height: auto;
    margin: 0 0 24px 0;
  }
`

const Text = styled.p`
${Body}
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 100%;
  }
`

const DividingLine = styled.div`
  height: 1px;
  border-bottom: 1px solid ${Primary};
`

const OrthodontistSection = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper>
      <GridLayout templateColumns={['repeat(5, 1fr)', 'repeat(12, 1fr)', 'repeat(12, 1fr)']} rowGap={['40px', '80px']}>
        <GridItem column={['1 / span 5', '2 / 12', '3 / span 8']}>
          <H2TitleWithSubTitle title={formatMessage({ id: 'title.orthodontics' })} subtitle={formatMessage({ id: 'subtitle.orthodontics' })} />
          <ContentContainer>
            <Content>
              <Text>
                『矯正為始，美學為終』
                <br />
                牙齒是整齊了，
                <br />
                但齒型好看嗎？齒色夠白嗎？
                <br />
                日不落為牙齒矯正注入美學元素，
                <br />
                不放過任何滿分笑容的細節。
              </Text>
              <Img src={OrthodontistImg1} alt='日不落牙醫集團-專業美學齒顎矯正' />
            </Content>
            <Content>
              <Text>
                全台首創南北醫師同步看診，
                <br />
                讓漫長的矯正療程不再因地點受限，
                <br />
                大幅減少時間成本，
                <br />
                實現在哪裡生活，
                <br />
                就在哪裡回診的理想。
              </Text>
              <Img src={OrthodontistImg2} alt='日不落牙醫集團-南北醫師同步看診' />
            </Content>
          </ContentContainer>
        </GridItem>
        <GridItem column={['3 / 4', '6 / 8']}>
          <DividingLine />
        </GridItem>
      </GridLayout>
    </Wrapper>
  )
}

export default OrthodontistSection
