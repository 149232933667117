import { useIntl } from 'gatsby-plugin-intl'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Button from '../../../components/Button'
import { H2TitleWithSubTitle } from '../../../components/Titles/H2Title'
import { space } from '../../../constants/length'
import { LightGrey } from '../../../constants/newColor'
import Desktop from './Desktop'
import Mobile from './Mobile'

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;

  background-color: ${LightGrey};
  padding: ${space.xl * 2}px 0;
`
const ButtonLink = styled.a`
  display: inline-block;
  margin: 24px auto 0 auto;
`
const Content = () => {
  const [viewport, setViewport] = useState('mobile')

  const handleRWD = () => {
    // iphone 14 pro max
    if (window.innerWidth > 430) setViewport('desktop')
    else setViewport('mobile')
  }

  useEffect(() => {
    window.addEventListener('resize', handleRWD)
    handleRWD()

    return () => {
      window.removeEventListener('resize', handleRWD)
    }
  }, [])

  if (viewport === 'desktop') {
    return <Desktop />
  } else {
    return <Mobile />
  }
}

const MainProduct = () => {
  const { formatMessage } = useIntl()
  return (
    <Wrapper>
      <H2TitleWithSubTitle title={formatMessage({ id: 'title.product' })} subtitle={formatMessage({ id: 'subtitle.product' })} marginBottom='36px' />
      <Content />
      <ButtonLink href='#footer_form'>
        <Button label='立即預約' type='primary' btnSquareColor={LightGrey} />
      </ButtonLink>
    </Wrapper>
  )
}

export default MainProduct
