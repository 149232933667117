import { useQuery } from '@apollo/client'
import { useIntl } from 'gatsby-plugin-intl'
import moment from 'moment'
import { map } from 'ramda'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import Button from '../../components/Button'
import { PatientCase } from '../../components/Cards'
import { GridLayout } from '../../components/Grid'
import { H2TitleWithSubTitle } from '../../components/Titles/H2Title'
import { space } from '../../constants/length'
import { viewport } from '../../constants/viewport'
import { CaseListQuery, CaseListQueryVariables } from '../../types/types'
import { caseListQuery } from '../case/List'
import { homePageMaxWidth } from './ClinicSection'

const Wrapper = styled.section`
  width: 100%;
  margin: 0 auto;
  max-width: ${homePageMaxWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const PatientCaseList = styled.div`
  width: 100%;
  margin: 0 auto;
  @media (max-width: ${viewport.tablet - 1}px) {
    max-width: 343px;
  }
`
const ButtonLink = styled.a`
  display: inline-block;
  margin: ${space.xl}px auto 0 auto;
`

const getCaseDisplay = () => {
  const [viewport, setViewport] = useState('small')

  const handleRWD = () => {
    if (window.innerWidth > 992 - 1) setViewport('large')
  }

  useEffect(() => {
    window.addEventListener('resize', handleRWD)
    handleRWD()

    return () => {
      window.removeEventListener('resize', handleRWD)
    }
  }, [])
  if (viewport === 'large') {
    return 4
  } else {
    return 3
  }
}

const CaseSection = () => {
  const { formatMessage } = useIntl()
  const { data } = useQuery<CaseListQuery, CaseListQueryVariables>(caseListQuery, {
    variables: {
      limit: getCaseDisplay()
    }
  })

  const displayCaseList = data?.cases?.docs ?? []
  return (
    <Wrapper>
      <H2TitleWithSubTitle title={formatMessage({ id: 'title.case' })} subtitle={formatMessage({ id: 'subtitle.case' })} />
      <PatientCaseList>
        <GridLayout templateColumns={['100%', 'repeat(3, 1fr)', 'repeat(4, 1fr)']} columnGap={[`${space.s}px`, `${space.l}px`]} rowGap={[`${space.s}px`, `${space.l}px`]}>
          {map(
            ({ id, doctor, location, publishedDate, thumbnailImage, title, category, alias }) => (
              <PatientCase
                key={id}
                caseId={id}
                category={category}
                imageUrl={thumbnailImage}
                doctor={doctor}
                publishedDate={moment(publishedDate).format('YYYY.MM.DD')}
                title={title}
                alias={alias}
                location={location}
              />
            ),
            displayCaseList
          )}
        </GridLayout>
      </PatientCaseList>
      <ButtonLink href='/case'>
        <Button label='MORE &#9658;' type='secondary' />
      </ButtonLink>
    </Wrapper>
  )
}

export default CaseSection
