import React from 'react'
import styled from 'styled-components'

import SloganBgImg from '../../assets/static/homepage/sloganBg.jpg'
import { LightGrey } from '../../constants/newColor'
import { H3 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'

const Wrapper = styled.section`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  background-image: url(${SloganBgImg});
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${viewport.tablet}px) {
    height: 267px;
    background-position: top center;
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    height: 267px;
    background-position: center;
  }
`

const Content = styled.h3`
  ${H3}
  color:${LightGrey};
`

const SloganArea = () => {
  return (
    <Wrapper>
      <Content>
        我們提供 日不落的服務 <br />
        讓你 舒服微笑每一天
        <br />
        <br />
        Your smile blooms under our sunlight
      </Content>
    </Wrapper>
  )
}

export default SloganArea
