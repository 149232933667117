import { Link } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import React, { useState } from 'react'
import Slider, { Settings } from 'react-slick'
import styled, { css } from 'styled-components'

import BudgetIcon from '../../../assets/images/budget-white.svg'
import CircleArrow from '../../../assets/images/circle-arrow-primary.svg'
import Carousel from '../../../components/Carousel'
import { space } from '../../../constants/length'
import { Primary, White } from '../../../constants/newColor'
import { Body, H3 } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'
import { products } from './products'

const productList = products.concat(products)

const CustomizedCarousel = styled(Carousel)`
  max-width: 1027px;
  min-width: 796px;
  width: 110vw;
  position: absolute;
  top: 8%;
  left: 50%;
  transform: translate(-50%, 0);
  @media (max-width: ${viewport.mobile}px) {
  }
`

const SliderWrapper = styled.div`
  width: 70vw;
  height: 560px;
  margin: 0 auto;
  position: relative;
  @media (max-width: ${viewport.mobile}px) {
    width: 100vw;
  }
`
const ProductWrapper = styled.div`
  position: relative;
`

interface ImageWrapperProps {
  url: string
}
const ImageWrapper = styled.a<ImageWrapperProps>`
  position: relative;
  display: block;
  width: 194px;
  height: 110px;
  margin: 0 auto;

  background-image: url(${props => props.url});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: ${viewport.tablet - 1}px) {
    width: 167px;
    height: 94px;
  }
`
const Budget = styled.div`
  background-color: ${Primary};
  color: ${White};
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
`

const Icon = styled.img`
  margin-right: ${space.s}px;
  width: calc(16px / 2);
  height: calc(16px / 2);
  object-fit: contain;
`

const ContentWrapper = styled.div`
  display: none;

  position: absolute;
  width: 50vw;
  top: 120%;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10;
  margin-top: ${space.l}px;

  @media (max-width: ${viewport.desktop - 1}px) {
    width: 60vw;
  }
`

const ProductName = styled.h4`
  ${H3}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${space.m}px;

  &:after {
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    margin-left: ${space.s}px;

    background-image: url(${CircleArrow});
    background-size: contain;
    background-repeat: no-repeat;
  }
`

const Content = styled.p`
  ${Body}
  text-align:center;
`

const TabPanelWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`

interface TabProps {
  active: boolean
}

const tabActiveStyle = css`
  background-color: ${Primary};
  color: ${White};
`

const Tab = styled.div<TabProps>`
  border: 1px solid ${Primary};
  padding: ${space.s}px ${space.m}px;
  border-radius: 8px;
  margin: 0 ${space.s}px;

  ${props => props.active && tabActiveStyle};

  &:hover {
    cursor: pointer;
  }
`
const scaleSize = 2.5

const customCarouselStyle = css`
  .slick-slide {
    padding: ${space.xl * 8}px 0 ${space.xl * 5}px 0;
    .img {
      transition: transform 0.5s;
      border: 2px solid ${Primary};
      border-radius: 8px;
      filter: grayscale(100%);
    }
    .budget {
      visibility: hidden;
    }
    @media (max-width: ${viewport.tablet - 1}px) {
      padding: ${space.xl * 5}px 0 ${space.xxl * 6}px 0;
    }
  }
  .slick-center {
    padding-top: ${space.xl * 7}px;
    .img {
      transform: scale(${scaleSize});
      border: 1px solid ${Primary};
      border-radius: 8px;
      transform-origin: bottom center;
      filter: grayscale(0);
      z-index: 9;
      @media (max-width: ${viewport.tablet - 1}px) {
        transform: scale(2);
      }
    }
    .budget {
      visibility: visible;
      font-size: calc((1.25rem) / ${scaleSize});
      border-radius: 0 0 7px 7px;
    }
    .content {
      display: block;
    }
    @media (max-width: ${viewport.tablet - 1}px) {
      padding-top: ${space.xl * 4}px;
    }
  }
`

interface ProductProps {
  title: string
  image: string
  link: string
  budget: string
  content: string
}

const Product = (props: ProductProps) => {
  const { title, image, link, budget, content } = props

  return (
    <ProductWrapper>
      <ImageWrapper className='img' url={image} href={link}>
        <Budget className='budget'>
          <Icon src={BudgetIcon} alt='' />
          {budget}
        </Budget>
      </ImageWrapper>
      <ContentWrapper className='content'>
        <Link to={link}>
          <ProductName>{title}</ProductName>
        </Link>
        <Content>{content}</Content>
      </ContentWrapper>
    </ProductWrapper>
  )
}

const Desktop = () => {
  const [currentSlide, setCurrentSlide] = useState(0)
  let slick: Slider | null = null
  const getRef = (ref: Slider | null) => {
    slick = ref
  }

  const afterChange = index => {
    setCurrentSlide(index)
  }

  const goto = (index: number) => {
    slick?.slickGoTo(index)
    setCurrentSlide(index)
  }

  const carouselProps: Settings = {
    slidesToShow: 3,
    autoplaySpeed: 3000,
    centerMode: true,
    autoplay: true,
    dots: false,
    infinite: true,
    arrows: false,
    pauseOnHover: false,
    afterChange: afterChange
  }

  return (
    <SliderWrapper>
      <TabPanelWrapper>
        {products.map((item, index) => (
          <Tab key={index} onClick={() => goto(index)} active={currentSlide === index || currentSlide === index + 3}>
            {item.budget}
          </Tab>
        ))}
      </TabPanelWrapper>
      <CustomizedCarousel getRef={getRef} customStyle={customCarouselStyle} {...carouselProps}>
        {map(
          info => (
            <Product title={info.title} image={info.image} link={info.link} budget={info.budget} content={info.content} />
          ),
          productList
        )}
      </CustomizedCarousel>
    </SliderWrapper>
  )
}

export default Desktop
