import React from 'react'
import CountUp from 'react-countup'
import styled from 'styled-components'

import CaseImg from '../../assets/static/homepage/case.svg'
import ClinicImg from '../../assets/static/homepage/clinic.svg'
import DoctorImg from '../../assets/static/homepage/doctor.svg'
import { GridItem, GridLayout } from '../../components/Grid'
import { space } from '../../constants/length'
import { Primary, Secondary } from '../../constants/newColor'
import { H2 as _H2 } from '../../constants/newText'
import { viewport } from '../../constants/viewport'
import { homePageMaxWidth } from './ClinicSection'

const Wrapper = styled.section`
  width: 100%;
  max-width: ${homePageMaxWidth}px;
  margin: 0 auto;
  padding: 0 24px;

  @media (max-width: ${viewport.tablet - 1}px) {
    display: none;
  }
`

const H2 = styled.h2`
  ${_H2}
  margin-bottom: 80px;
  @media (max-width: ${viewport.tablet - 1}px) {
    margin-bottom: 40px;
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Img = styled.img``

const EmphasizeText = styled.div`
  display: flex;
  color: ${Primary};
  align-items: flex-end;
  margin: 8px auto;

  @media (max-width: ${viewport.desktop}px) {
    font-size: 1.25rem; //20px
  }

  @media (min-width: ${viewport.desktop + 1}px) {
    font-size: 1.5rem; //24px
  }
`

const Number = styled.span`
  font-size: 4rem;
  font-weight: 500;
  line-height: 4rem;
  margin-right: 4px;
`

const Title = styled.h3`
  ${_H2}
  color: ${Secondary};
`

const DataSection = () => {
  return (
    <Wrapper>
      <GridLayout templateColumns={['repeat(12, 1fr)']}>
        <GridItem column={['1 / span 12']}>
          <H2>日不落牙醫集團在全台已有</H2>
          <GridLayout templateColumns={['100%', 'repeat(3, 1fr)']} rowGap={[`${space.xxl}px`, 'unset']}>
            <Item>
              <Img src={ClinicImg} />
              <EmphasizeText>
                <Number>
                  <CountUp start={0} end={5} duration={1.25} delay={0} />
                </Number>
                間
              </EmphasizeText>
              <Title>隱形矯正教育中心</Title>
            </Item>
            <Item>
              <Img src={CaseImg} />
              <EmphasizeText>
                <Number>
                  <CountUp start={5000} end={7000} duration={5} delay={0} />
                </Number>
                +
              </EmphasizeText>
              <Title>矯正成功案例</Title>
            </Item>
            <Item>
              <Img src={DoctorImg} />
              <EmphasizeText>
                <Number>
                  <CountUp start={5} end={15} duration={5} delay={0} />
                </Number>
                位
              </EmphasizeText>
              <Title>矯正專科醫師</Title>
            </Item>
          </GridLayout>
        </GridItem>
      </GridLayout>
    </Wrapper>
  )
}

export default DataSection
