import { map } from 'ramda'
import React from 'react'
import styled, { css, keyframes } from 'styled-components'

import DownArrow from '../../../assets/images/down-arrow.svg'
import Carousel from '../../../components/Carousel'
import { space } from '../../../constants/length'
import { Grey, Primary } from '../../../constants/newColor'
import { viewport } from '../../../constants/viewport'
import { SliderInfo } from '.'

const bounce = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-20px);
  }
`

const Wrapper = styled.div`
  position: relative;
  padding-top: ${space.l * 2}px;
`
const CarouselDotsVisible = styled.div`
  max-width: 1280px;
  width: 80%;
  border: 1px solid ${Primary};
  position: relative;
  border-radius: 16px;
  margin: 0 auto;

  @media (max-width: ${viewport.tablet - 1}px) {
    width: 90%;
    border-radius: 16px;
  }
`

const CarouselWrapper = styled.div`
  border-radius: 16px;
  overflow: hidden;
  > div {
    padding: 0;
  }
`

const BackgroundImage = styled.img`
  width: 100%;

  @media (max-width: ${viewport.tablet - 1}px) {
    height: 30vh;
    object-fit: cover;
  }
`

interface ScrollDownProps {
  src: string
}

const ScrollDownIcon = styled.div<ScrollDownProps>`
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 1px solid ${Primary};
  border-radius: 50%;

  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;

  animation: ${bounce} 0.5s alternate-reverse linear infinite;
`

const customCarouselStyle = css`
  .slick-list,
  .slick-track,
  .slick-slide > div {
    height: 100%;
  }

  .slick-slider {
    position: static;
  }
  .slick-dots {
    position: absolute;
  }

  .slick-dots li {
    bottom: -16px;
  }
  .slick-dots li button:before {
    color: ${Grey};
    font-size: 24px;
    opacity: 1;
  }
  .slick-dots li.slick-active button:before {
    color: ${Primary};
    opacity: 1;
  }
`

const CarouselItem = props => {
  const { href, title, ...restProps } = props

  if (href) {
    return (
      <a href={href} title={title}>
        <BackgroundImage {...restProps} />
      </a>
    )
  }
  return <BackgroundImage {...restProps} />
}

interface DesktopProps {
  sliderInfoList: SliderInfo[]
}

const Desktop: React.FC<DesktopProps> = props => {
  const { children, sliderInfoList } = props
  return (
    <Wrapper>
      <CarouselDotsVisible>
        <CarouselWrapper>
          <Carousel customStyle={customCarouselStyle} arrows={false} autoplay autoplaySpeed={3000}>
            {map(
              sliderInfo => (
                <CarouselItem key={sliderInfo.description} src={sliderInfo.image} href={sliderInfo.link} title={sliderInfo.description} alt={sliderInfo.description} />
              ),
              sliderInfoList
            )}
          </Carousel>
        </CarouselWrapper>
      </CarouselDotsVisible>
      {children}
      <ScrollDownIcon src={DownArrow} />
    </Wrapper>
  )
}

export default Desktop
