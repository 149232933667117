import { useQuery } from '@apollo/client'
import { Link as _Link, useIntl } from 'gatsby-plugin-intl'
import { map } from 'ramda'
import React, { useState } from 'react'
import Slider, { Settings } from 'react-slick'
import styled from 'styled-components'

import DoctorMissingImg from '../../../assets/static/doctors/doctorImgMissing.png'
import Button from '../../../components/Button'
import Carousel from '../../../components/Carousel'
import { GridLayout } from '../../../components/Grid'
import { H2TitleWithSubTitle } from '../../../components/Titles/H2Title'
import { space } from '../../../constants/length'
import { H4 } from '../../../constants/newText'
import { viewport } from '../../../constants/viewport'
import { DoctorListQuery, DoctorListQueryVariables } from '../../../types/types'
import { doctorListQuery } from '../../team'
import Doctor from './Doctor'

const Wrapper = styled.section``

const Link = styled(_Link)`
  margin: 0 auto;
`

const CarouselWrapper = styled.div`
  padding-top: ${space.l * 3}px;
  margin-bottom: ${space.l}px;
  @media (max-width: ${viewport.tablet}px) {
    padding-top: ${space.xxl}px;
  }
`

const Others = styled.h4`
  ${H4}
  &:hover {
    opacity: 0.7;
  }
`
const ItemWrapper = styled.div`
  position: relative;
`

const TabPanelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  margin: 0 auto;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: ${viewport.tablet - 1}px) {
    width: calc(100% - ${space.s * 2}px);
    justify-content: flex-start;
  }
`

const TabPanel = styled.div<{ active: boolean }>`
  width: 75px;
  height: 75px;
  flex-shrink: 0;
  margin: 0 ${space.m}px;

  &:first-child {
    margin: 0;
  }
  @media (max-width: ${viewport.tablet - 1}px) {
    width: 60px;
    height: 60px;
    margin: 0 ${space.s}px;
  }

  ${props => (props.active ? `opacity: 1` : `opacity: 0.3`)}
`

const DoctorImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`

const ButtonLink = styled.a`
  display: inline-block;
  margin: 0 auto;
`

const EliteDoctorsSection = () => {
  const { formatMessage } = useIntl()
  const [currentSlide, setCurrentSlide] = useState(0)

  let slick: Slider | null = null
  const getRef = (ref: Slider | null) => {
    slick = ref
  }

  const goto = (index: number) => {
    slick?.slickGoTo(index)
    setCurrentSlide(index)
  }

  const afterChange = index => {
    setCurrentSlide(index)
  }

  const carouselProps: Settings = {
    autoplay: true,
    autoplaySpeed: 3000,
    afterChange: afterChange,
    infinite: true,
    dots: false,
    pauseOnHover: false
  }

  const { data } = useQuery<DoctorListQuery, DoctorListQueryVariables>(doctorListQuery, {
    variables: {
      limit: 10,
      sort: 'order',
      query: {
        elite: true
      }
    }
  })

  const pageData = data?.doctorsList?.docs ?? []

  return (
    <Wrapper>
      <H2TitleWithSubTitle title={formatMessage({ id: 'title.elite' })} subtitle={formatMessage({ id: 'subtitle.elite' })} marginBottom='40px' />
      <ItemWrapper>
        <TabPanelWrapper>
          {pageData.map((data, index) => (
            <TabPanel key={index} onClick={() => goto(index)} active={currentSlide === index}>
              <DoctorImg src={data.image || DoctorMissingImg} alt={data.name} />
            </TabPanel>
          ))}
        </TabPanelWrapper>
        <CarouselWrapper>
          <Carousel getRef={getRef} {...carouselProps}>
            {map(
              ({ id, name, title, location, image, strength, commend }) => (
                <Doctor key={id} name={name} title={title} location={location} doctorImageUrl={image} strength={strength} commend={commend} />
              ),
              pageData
            )}
          </Carousel>
        </CarouselWrapper>
      </ItemWrapper>
      <GridLayout templateColumns={['100%']} rowGap={['24px']}>
        <Link to='/team'>
          <Others>查看醫師團隊</Others>
        </Link>
        <ButtonLink href='#footer_form'>
          <Button label='立即預約' type='primary' />
        </ButtonLink>
      </GridLayout>
    </Wrapper>
  )
}

export default EliteDoctorsSection
