import { Link } from 'gatsby-plugin-intl'
import React from 'react'
import styled from 'styled-components'

import BudgetIcon from '../../../assets/images/budget-white.svg'
import CircleArrow from '../../../assets/images/circle-arrow-primary.svg'
import { space } from '../../../constants/length'
import { Primary, White } from '../../../constants/newColor'
import { products } from './products'

const Wrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`

const ProductWrapper = styled.div`
  margin-bottom: ${space.xl * 2}px;

  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-bottom: ${space.xxl}px;
  }
`

const ImgWrapper = styled.div`
  position: relative;
  width: 100%;
  border: 1px solid ${Primary};
  border-radius: 8px;
  overflow: hidden;
`

const Img = styled.img`
  width: 100%;
  height: auto;
`
const ProductName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 1.1rem;
  font-weight: 500;
  color: ${Primary};
  margin: ${space.m}px auto;

  &:after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    margin-left: ${space.s}px;

    background-image: url(${CircleArrow});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
  }
`
const Budget = styled.div`
  width: 100%;
  height: 2rem;
  background-color: ${Primary};
  color: ${White};

  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;

  &:before {
    content: '';
    display: block;
    width: 1rem;
    height: 1rem;
    margin-right: ${space.s}px;

    background-image: url(${BudgetIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
`

const Content = styled.div`
  font-size: 1rem;
  line-height: 2rem;
  font-weight: 300;
  text-align: center;
`

const Mobile = () => {
  return (
    <Wrapper>
      {products.map(product => (
        <ProductWrapper key={product.title}>
          <ImgWrapper>
            <Img src={product.image} alt={product.title} />
            <Budget>{product.budget}</Budget>
          </ImgWrapper>
          <Link to={product.link}>
            <ProductName>{product.title}</ProductName>
          </Link>
          <Content>{product.content}</Content>
        </ProductWrapper>
      ))}
    </Wrapper>
  )
}

export default Mobile
