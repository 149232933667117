import { PageRendererProps } from 'gatsby'
import { LocalBusinessJsonLd } from 'gatsby-plugin-next-seo'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import { GridLayout } from '../../components/Grid'
import Layout from '../../components/Layout'
import Seo from '../../components/Seo'
import { space } from '../../constants/length'
import Banner from './Banner'
import CaseSection from './CaseSection'
import ClinicSection from './ClinicSection'
import DataSection from './DataSection'
import EliteDoctorsSection from './EliteDoctorsSection'
import MainProduct from './MainProduct'
import NewsSection from './NewsSection'
import OrthodontistSection from './OrthodontistSection'
import SloganArea from './SloganArea'

const StructuredData = () => {
  return (
    <LocalBusinessJsonLd
      type='LocalBusiness'
      id='https://sunlight.dentist/'
      description='台北美齒專科 日亞美牙醫診所擁有多項矯正認證的醫療團隊，提供舒適無壓迫感的環境透過新五感方式給你全新的矯正體驗，讓你在舒適療程中換得一口潔白美齒。'
      priceRange='$$'
      name='日亞美牙醫診所'
      url='https://sunlight.dentist/'
      telephone='02-2771-3727'
      address={{
        streetAddress: '大安區敦化南路一段219號4樓',
        addressLocality: '台北市',
        postalCode: '10690',
        addressCountry: 'TW'
      }}
      openingHours={{
        dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
        opens: '14:00',
        closes: '21:00'
      }}
      geo={{
        latitude: '25.041115718289728',
        longitude: '121.54923608321214'
      }}
      images={['https://sunlight.dentist/static/1x1/img-og.jpg', 'https://sunlight.dentist/static/4x3/img-og.jpg', 'https://sunlight.dentist/static/16x9/img-og.jpg']}
    />
  )
}

const AffiliatesCPL = () => {
  return (
    <>
      <Helmet>
        {/* <!-- Affiliates CPL code --> */}
        <script type='text/javascript'>
          {`
          (function () {
            var VARemoteLoadOptions = {
              whiteLabel: { id: 8, siteId: 2790, domain: 't.adotone.com' },
              locale: "en-US", mkt: true 
            };
            (function (c, o, n, v, e, r, l, y) {
              c['VARemoteLoadOptions'] = e; r = o.createElement(n), l = o.getElementsByTagName(n)[0]; r.async = 1; r.src = v; l.parentNode.insertBefore(r, l);
            })(window, document, 'script', 'https://cdn.adotone.com/javascripts/va.js', VARemoteLoadOptions); 
          })();
          `}
        </script>
      </Helmet>
    </>
  )
}

const Content = styled.div`
  margin: ${space.xxl * 2}px auto;
`

const Index = (props: PageRendererProps) => {
  return (
    <>
      <Layout {...props}>
        <Seo />
        <AffiliatesCPL />
        <StructuredData />
        <Banner />
        <Content>
          <GridLayout templateColumns={['100%']} rowGap={['120px']}>
            <DataSection />
            <ClinicSection />
            <MainProduct />
            <OrthodontistSection />
            <EliteDoctorsSection />
            <SloganArea />
            <CaseSection />
            <NewsSection />
          </GridLayout>
        </Content>
      </Layout>
    </>
  )
}

export default Index
