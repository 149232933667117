import { map } from 'ramda'
import React from 'react'
import styled, { css } from 'styled-components'

import Carousel from '../../../components/Carousel'
import { SliderInfo } from '.'

const MobileWrapper = styled.div``

const BackgroundImg = styled.img`
  width: 100%;
  height: 30vh;
  object-fit: cover;
`

const CarouselItem = props => {
  const { href, title, ...restProps } = props

  if (href) {
    return (
      <a href={href} title={title}>
        <BackgroundImg {...restProps} />
      </a>
    )
  }
  return <BackgroundImg {...restProps} />
}

const customCarouselStyle = css`
  padding: 0;
  .slick-slider {
    position: relative;
  }

  .slick-dots {
    position: absolute;
    bottom: 8px;
  }
`

interface MobileProps {
  sliderInfoList: SliderInfo[]
}

const Mobile: React.FC<MobileProps> = props => {
  const { children, sliderInfoList } = props

  return (
    <MobileWrapper>
      <Carousel customStyle={customCarouselStyle} autoplay autoplaySpeed={3000}>
        {map(
          sliderInfo => (
            <CarouselItem key={`image-${sliderInfo.description}`} src={sliderInfo.image} alt={sliderInfo.description} href={sliderInfo.link} />
          ),
          sliderInfoList
        )}
      </Carousel>
      {children}
    </MobileWrapper>
  )
}

export default Mobile
