import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { space } from '../../../constants/length'
import { Secondary } from '../../../constants/newColor'
import { H2, H3 } from '../../../constants/newText'
import { BannerListQuery, BannerListQueryVariables } from '../../../types/types'
import Desktop from './Desktop'
import Mobile from './Mobile'

export interface SliderInfo {
  image: string
  description?: string
  link?: string
  active: boolean
  order: number
}

const bannerListQuery = gql`
  query BannerList($query: BannersQuery, $page: Int, $limit: Int, $sort: String) {
    bannersList(query: $query, page: $page, limit: $limit, sort: $sort) {
      docs {
        id
        image
        description
        link
        active
        order
      }
      page
      limit
      total
    }
  }
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${space.xl * 2}px;
  margin-bottom: ${space.xl * 2}px;
`

const Title = styled.h1`
  ${H2}
  margin-bottom: ${space.s}px;
`

const Content = styled.h2`
  color: ${Secondary};
  ${H3}
`

const SunlightInfo = () => {
  return (
    <Wrapper>
      <Title>日不落牙醫集團</Title>
      <Content>全台連鎖牙醫集團 | 隱形矯正教育中心</Content>
    </Wrapper>
  )
}

const Banner = () => {
  const [viewport, setViewport] = useState('mobile')

  const handleRWD = () => {
    // iphone 14 pro max
    if (window.innerWidth > 430) setViewport('desktop')
    else setViewport('mobile')
  }

  useEffect(() => {
    window.addEventListener('resize', handleRWD)
    handleRWD()

    return () => {
      window.removeEventListener('resize', handleRWD)
    }
  }, [])

  const { data } = useQuery<BannerListQuery, BannerListQueryVariables>(bannerListQuery, {
    variables: {
      sort: 'order',
      query: {
        active: true
      }
    }
  })

  const sliderInfoList = data?.bannersList?.docs ?? []

  if (viewport === 'desktop') {
    return <Desktop sliderInfoList={sliderInfoList}>{SunlightInfo()}</Desktop>
  } else {
    return <Mobile sliderInfoList={sliderInfoList}>{SunlightInfo()}</Mobile>
  }
}

export default Banner
