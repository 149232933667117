import OrthoImg from '../../../assets/static/homepage/ortho.png'
import VeneerImg from '../../../assets/static/homepage/veneer.png'
import WhiteningImg from '../../../assets/static/homepage/whitening.png'

export const products = [
  {
    title: '隱形牙套',
    image: OrthoImg,
    budget: '16 ~ 19 萬',
    content: '用數位矯正軟體估出牙齒的位移變化，進而客製出個人專屬牙套，讓牙齒整齊排列。另有，隱形牙套微調方案『9萬9樂齒微矯正』，適用門牙矯正、二次矯正等案例。',
    link: '/service/digital'
  },
  {
    title: '陶瓷貼片',
    image: VeneerImg,
    budget: '11 ~ 13 萬',
    content: '透過陶瓷貼片塑造出理想的齒型、齒色，彌補牙齒色素沉澱、大小不均等問題。舉例：上排微笑區6顆',
    link: '/service/veneers'
  },
  {
    title: '冷光美白',
    image: WhiteningImg,
    budget: '0.5 ~ 1.5 萬',
    content: '利用溫度改變不明顯的藍光催化牙齒上的藥劑，改變牙齒碳結構還原齒色，能快速看見亮白效果。',
    link: '/service/teethWhitening#cold-light'
  }
]
